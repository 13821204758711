import React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'

//Hey whats up
/*
$$\   $$\                               $$\      $$\ $$\                  $$\  $$\                $$\   $$\           
$$ |  $$ |                              $$ | $\  $$ |$$ |                 $$ | $  |               $$ |  $$ |          
$$ |  $$ | $$$$$$\  $$\   $$\           $$ |$$$\ $$ |$$$$$$$\   $$$$$$\ $$$$$$\\_/ $$$$$$$\       $$ |  $$ | $$$$$$\  
$$$$$$$$ |$$  __$$\ $$ |  $$ |          $$ $$ $$\$$ |$$  __$$\  \____$$\\_$$  _|  $$  _____|      $$ |  $$ |$$  __$$\ 
$$  __$$ |$$$$$$$$ |$$ |  $$ |          $$$$  _$$$$ |$$ |  $$ | $$$$$$$ | $$ |    \$$$$$$\        $$ |  $$ |$$ /  $$ |
$$ |  $$ |$$   ____|$$ |  $$ |          $$$  / \$$$ |$$ |  $$ |$$  __$$ | $$ |$$\  \____$$\       $$ |  $$ |$$ |  $$ |
$$ |  $$ |\$$$$$$$\ \$$$$$$$ |$$\       $$  /   \$$ |$$ |  $$ |\$$$$$$$ | \$$$$  |$$$$$$$  |      \$$$$$$  |$$$$$$$  |
\__|  \__| \_______| \____$$ |$  |      \__/     \__|\__|  \__| \_______|  \____/ \_______/        \______/ $$  ____/ 
                    $$\   $$ |\_/                                                                           $$ |      
                    \$$$$$$  |                                                                              $$ |      
                     \______/                                                                               \__|      
*/
// SVGs

export default () => (
    <Layout className="home">
        <SEO
            title="Home"
            description={`👋 Hey, I’m Ian Banatoski! I’m a Fullstack Software Engineer whose expertise lies in UI/UX + data-visualization.`}
        />
        <section className="padding-top-12 padding-bottom-6 hero position-relative">
            <img
                className="paper-airplane"
                src={
                    'https://d2jotzgct8q460.cloudfront.net/svgs/Paper-Airplane.svg'
                }
                alt="Paper Airplane"
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 offset-lg-1 order-lg-1 col-md-6 offset-md-3 col-8 offset-2 margin-bottom-3">
                        <img
                            className="hero-svg"
                            src={
                                'https://d2jotzgct8q460.cloudfront.net/svgs/PersonBeanBag.svg'
                            }
                            alt="Hero BeanBag"
                        />
                    </div>
                    <div className="col-lg-7 col-md-12 order-lg-0 align-self-center">
                        <h1 className="margin-bottom-3">
                            <span role="img" aria-label="wave-emoji">
                                👋
                            </span>{' '}
                            Hey, I’m Ian Banatoski!
                        </h1>
                        <h2 className="weight-500 margin-bottom-3">
                            I’m a{' '}
                            <span className="red-underline no-hover">
                                Fullstack Software Engineer
                            </span>{' '}
                            focusing on UI/UX + data-visualization.
                        </h2>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cta-row">
                                    <Link
                                        to="/about"
                                        className="secondary-button margin-right-3 margin-bottom-1"
                                    >
                                        About Me
                                    </Link>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://d2jotzgct8q460.cloudfront.net/files/Resume_Ian_Banatoski.pdf"
                                        className="primary-button primary-button__red hover margin-right-3 margin-bottom-1"
                                    >
                                        Resume
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-6 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/rally-for-restaurants"
                            className="card r4r"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">
                                    Web Application
                                </span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Rally for Restaurants
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    Toast Inc.
                                </span>
                            </p>
                            <div className="img-container d-flex justify-content-center">
                                <img
                                    className="set-width"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/svgs/Rally-For-Restaurants.svg'
                                    }
                                    alt="Rally for Restaurants Logo"
                                />
                            </div>
                            <img
                                className="bg-img"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/r4r-screenshot.jpg'
                                }
                                alt="Rally for Restaurants"
                            />
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">AWS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Leaflet</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Algolia</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Javascript</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/toast-success-report"
                            className="card toast"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">
                                    Data Visualization
                                </span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Industry Success Report
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    Toast Inc.
                                </span>
                            </p>
                            <div className="img-container d-flex justify-content-center align-items-center">
                                <img
                                    className="set-width"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/svgs/Toast-Logo.svg'
                                    }
                                    alt="Toast Logo"
                                />
                            </div>
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">React</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">D3 JS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Node</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Python</div>
                                </div>
                            </div>
                            <img
                                className="bg-img"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/success-report.jpg'
                                }
                                alt="Success Report BG"
                            />
                        </Link>
                    </div>
                    <div className="col-md-12 margin-bottom-3">
                        <Link
                            to="/projects/toast-ecommerce"
                            className="card toast"
                        >
                            <div className="row">
                                <div className="col-md-4 align-self-center">
                                    <div className="img-container d-flex justify-content-center align-items-center">
                                        <img
                                            src={
                                                'https://d2jotzgct8q460.cloudfront.net/imgs/Flex-Bundle.png'
                                            }
                                            alt="Toast Flex Bundle"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="margin-bottom-2">
                                        <span className="highlight-text">
                                            Ecommerce
                                        </span>
                                    </h4>
                                    <h3 className="margin-bottom-4">
                                        <span className="highlight-text">
                                            Understanding the Demand for buying
                                            Toast Online
                                        </span>
                                    </h3>
                                    <p className="weight-600 margin-bottom-10">
                                        <span className="highlight-text">
                                            Toast Inc.
                                        </span>
                                    </p>
                                    <div className="tag-container">
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">HTML</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Javascript
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Marketo
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Craft CMS
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-filter-layer"></div>
                            <img
                                className="bg-img bg-top"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/Toast-Ecomm.jpg'
                                }
                                alt="Toast Ecomm"
                            />
                        </Link>
                    </div>
                    <div className="col-md-6 margin-bottom-3">
                        <Link
                            to="/projects/beautiful-clean-coal"
                            className="card coal"
                        >
                            <h4 className="margin-bottom-2">
                                <span className="highlight-text">
                                    Data Visualization
                                </span>
                            </h4>
                            <h3 className="margin-bottom-4">
                                <span className="highlight-text">
                                    Beautiful Clean Coal
                                </span>
                            </h3>
                            <p className="weight-600">
                                <span className="highlight-text">
                                    WPI Project
                                </span>
                            </p>
                            <div className="img-container d-flex justify-content-center">
                                <img
                                    className="feature-img"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/imgs/tree-clouds.png'
                                    }
                                    alt="Tree with Clouds"
                                />
                            </div>
                            <div className="tag-container">
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">React JS</div>
                                </div>
                                <div className="tag margin-bottom-1 margin-right-1">
                                    <div className="content">Semiotic</div>
                                </div>
                            </div>
                            <img
                                className="bg-img"
                                src={
                                    'https://d2jotzgct8q460.cloudfront.net/imgs/power-plant2.png'
                                }
                                alt="Power Plant"
                            />
                        </Link>
                    </div>
                    <div className="col-md-6 margin-bottom-3">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to="/projects" className="card else">
                                    <div className="row">
                                        <div className="col-4">
                                            <img
                                                src={
                                                    'https://d2jotzgct8q460.cloudfront.net/svgs/peep-32.svg'
                                                }
                                                alt="Peep 32"
                                            />
                                        </div>
                                        <div className="col-8">
                                            <h3 className="">
                                                <span className="highlight-text">
                                                    What else is up with me
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
